import { useNode } from '@craftjs/core';
import styled from 'styled-components';

import Wrapper from '../wrapper';
import ImageSettings from './settings';

const StyledImage = styled.img`
	max-width: ${(props) => props.width};
`;

const Image = ({ src, alt, caption, width }) => {
	const {
		connectors: { connect, drag },
		isActive,
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));

	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper active={isActive}>
				<figure>
					<StyledImage width={width} src={src} alt={alt} />
					<figcaption>{caption}</figcaption>
				</figure>
			</Wrapper>
		</div>
	);
};

Image.craft = {
	name: 'Immagine',
	props: {
		alt: '',
		caption: '',
		src: 'https://via.placeholder.com/480x480/883333',
		width: '100%',
	},
	related: {
		settings: ImageSettings,
	},
};

export default Image;
