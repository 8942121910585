import { useNode } from '@craftjs/core';
import ReactImageGallery from 'react-image-gallery';
import styled from 'styled-components';

import Wrapper from '../wrapper';
import ImageGallerySettings from './settings';

const ImageGalleryWrapper = styled.div`
	.image-gallery-left-nav .image-gallery-svg,
	.image-gallery-right-nav .image-gallery-svg {
		height: 32px;
		width: 32px;
	}

	.image-gallery-fullscreen-button .image-gallery-svg,
	.image-gallery-play-button .image-gallery-svg {
		height: 24px;
		width: 24px;
	}
`;

const ImageGallery = ({ images }) => {
	const {
		connectors: { connect, drag },
		isActive,
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));
	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper active={isActive}>
				<ImageGalleryWrapper>
					<ReactImageGallery items={images} />
				</ImageGalleryWrapper>
			</Wrapper>
		</div>
	);
};

ImageGallery.craft = {
	name: 'Galleria Immagini',
	props: {
		images: [],
	},
	related: {
		settings: ImageGallerySettings,
	},
};

export default ImageGallery;
