import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import * as colors from '@material-ui/core/colors';
import styled from 'styled-components';

import { OptionTitle } from '../../controls/styled';
import TextInputField from '../../controls/textField';

const Hint = styled.p`
	width: 100%;
	white-space: initial;
	color: ${colors.blueGrey[300]};
	font-size: 12px;
	font-style: italic;
`;

const YouTubeSettings = () => {
	const {
		source,
		actions: { setProp },
	} = useNode((node) => ({
		source: node.data.props.source,
	}));
	const onYoutubeChange = (e) => {
		setProp((props) => (props.source = e.target.value));
	};
	return (
		<Box>
			<OptionTitle>Incorpora video YouTube</OptionTitle>
			<TextInputField value={source} onChange={onYoutubeChange} />
			<Hint>
				Nella pagina del video cliccare su <strong>Condividi &gt; Incorpora &gt; Copia</strong> ed incollare il codice
				nel box sopra.
			</Hint>
		</Box>
	);
};
export default YouTubeSettings;
