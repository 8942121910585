import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

const Title = styled(Typography)`
	font-size: 1rem;
	color: ${colors.blueGrey[700]};
	@media (max-width: 1024px) {
		font-size: 0.8rem !important;
	}
`;

const StyledAccordion = styled(Accordion)`
	box-shadow: none !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);

	&::before {
		background-color: transparent !important;
	}
`;

const ControlWrapper = ({ title, children }) => (
	<StyledAccordion>
		<AccordionSummary expandIcon={<ExpandMoreIcon />}>
			<Title>{title}</Title>
		</AccordionSummary>
		<AccordionDetails>{children}</AccordionDetails>
	</StyledAccordion>
);

export default ControlWrapper;
