import { Element, useEditor, useNode } from '@craftjs/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useRef, useState } from 'react';

import Container from '../../container';
import Wrapper from '../../wrapper';
import { Editable, Expandable, ExpandableContent, Expander, StripedBlock } from './styled';

const EditableShowMore = () => {
	const {
		connectors: { connect, drag },
	} = useNode();
	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper>
				<Editable>
					<StripedBlock />
					<Element id="show_more_container" is={Container} canvas />
				</Editable>
			</Wrapper>
		</div>
	);
};

const DisplayedShowMore = () => {
	const contentRef = useRef(null);
	const [expanded, setExpanded] = useState(false);
	return (
		<Expandable>
			<Expander>
				<IconButton onClick={() => setExpanded(!expanded)}>{expanded ? <RemoveIcon /> : <AddIcon />}</IconButton>
			</Expander>
			<ExpandableContent ref={contentRef} height={expanded ? contentRef.current?.scrollHeight : 0}>
				<Element id="show_more_container" is={Container} canvas />
			</ExpandableContent>
		</Expandable>
	);
};

const ShowMore = () => {
	const { enabled: isEditorEnabled } = useEditor((state) => ({ enabled: state.options.enabled }));
	return isEditorEnabled ? <EditableShowMore /> : <DisplayedShowMore />;
};

ShowMore.craft = {
	displayName: 'Mostra di più',
};

export default ShowMore;
