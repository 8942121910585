import { useEditor } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createElement } from 'react';
import styled from 'styled-components';

import { Hint, Title } from './styled';

const SettingsContainer = styled(Paper)`
	padding: 16px;
	overflow: hidden;
`;

const ScrollableWrapper = styled(Box)`
	position: relative;
	height: calc(100% - 64px);
	overflow: hidden;
`;

const Scrollable = styled(Box)`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: auto;
`;

export const SettingsPanel = () => {
	const { actions, selected } = useEditor((state, query) => {
		const currentNodeId = state.events.selected;
		let selected;
		if (currentNodeId) {
			selected = {
				id: currentNodeId,
				name: state.nodes[currentNodeId].data.name,
				settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
				isDeletable: query.node(currentNodeId).isDeletable(),
			};
		}
		return { selected };
	});
	return (
		<SettingsContainer>
			<Box pb={2} height="64px">
				<Title>Impostazioni</Title>
				<Hint>Seleziona un blocco per visualizzare le impostazioni</Hint>
			</Box>
			{selected && (
				<ScrollableWrapper>
					<Scrollable>{selected.settings && createElement(selected.settings)}</Scrollable>
				</ScrollableWrapper>
			)}
		</SettingsContainer>
	);
};
