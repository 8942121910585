import '@atlantis/react-page-builder';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all';
import './index.css';

import LoadingSpinner from 'components/loading-spinner';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'redux-api';

import { SpinnerContainer } from './styled';

const App = React.lazy(() => import('./App'));

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Suspense
				fallback={
					<SpinnerContainer>
						<LoadingSpinner />
					</SpinnerContainer>
				}
			>
				<App />
			</Suspense>
		</Provider>
	</React.StrictMode>,
	document.getElementById('atlas'),
);
