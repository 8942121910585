import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import styled from 'styled-components';

import { OptionTitle } from '../../controls/styled';
import TextInputField from '../../controls/textField';
import { api } from '../../editor/api';

const StyledInput = styled.input`
	display: none;
`;

const FileSettings = () => {
	const {
		id,
		description,
		actions: { setProp },
	} = useNode((node) => ({
		description: node.data.props.description,
	}));
	const onMediaChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.readAsText(file);
		api.onFileUpload(file).then((url) => {
			setProp((props) => (props.src = url));
		});
	};
	const uploaderId = `file-${id}`;
	return (
		<Box>
			<OptionTitle>File</OptionTitle>
			<StyledInput type="file" accept="*/*" id={uploaderId} onChange={onMediaChange} />
			<label htmlFor={uploaderId}>
				<Button variant="contained" color="primary" component="span" startIcon={<PublishIcon />}>
					Carica
				</Button>
			</label>
			<Box height="16px" />
			<OptionTitle>Descrizione</OptionTitle>
			<TextInputField
				value={description}
				onChange={(e) => {
					setProp((props) => (props.description = e.target.value));
				}}
			/>
		</Box>
	);
};
export default FileSettings;
