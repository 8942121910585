import { useEditor, useNode } from '@craftjs/core';
import Typography from '@material-ui/core/Typography';
import ContentEditable from 'react-contenteditable';

import Wrapper from '../wrapper';
import TextSettings from './settings';

const Text = ({
	color,
	text,
	fontSize,
	textAlign,
	fontWeight,
	paddingTop,
	paddingLeft,
	paddingBottom,
	paddingRight,
	fontFamily,
}) => {
	const {
		connectors: { connect, drag },
		isActive,
		actions: { setProp },
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));
	const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }));
	const style = {
		paddingTop,
		paddingLeft,
		paddingBottom,
		paddingRight,
		textAlign,
		color,
		fontWeight,
		fontSize: `${fontSize}px`,
		fontFamily,
	};
	return enabled ? (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper active={isActive}>
				<ContentEditable
					html={text}
					onChange={(e) => setProp((props) => (props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, '')))} // TODO: rimuovi i tag eccetto br
					tagName="p"
					style={style}
				/>
			</Wrapper>
		</div>
	) : (
		<Typography style={style} dangerouslySetInnerHTML={{ __html: text }} />
	);
};

Text.craft = {
	displayName: 'Paragrafo',
	props: {
		text: 'Testo',
		fontSize: 16,
		textAlign: 'left',
		color: 'rgba(0, 0, 0, 1)',
		fontWeight: '500',
		paddingTop: 8,
		paddingLeft: 8,
		paddingBottom: 8,
		paddingRight: 8,
		fontFamily: 'Poppins',
	},
	related: {
		settings: TextSettings,
	},
};

export default Text;
