import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import styled from 'styled-components';

import { OptionTitle } from '../../controls/styled';
import { DeleteImageButton } from '../../delete-image-button';
import { api } from '../../editor/api';

const StyledInput = styled.input`
	display: none;
`;

const ImagesList = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 16px;
`;

const ImageListItem = styled.div`
	position: relative;
	width: 100%;

	img {
		width: 100%;
	}
`;

const ImageGallerySettings = () => {
	const {
		id,
		actions: { setProp },
		images,
	} = useNode((node) => ({
		images: node.data.props.images,
	}));
	const onImageRemove = (index) => {
		const newlist = images.filter((img, i) => i !== index);
		setProp((props) => (props.images = newlist));
	};
	const onImageChange = (e) => {
		const file = e.target.files[0];
		const maxAllowedSize = 5 * 1024 * 1024;
		if (file.size > maxAllowedSize) {
			alert('Maximum file size is 5MB');
			e.target.value = '';
			return;
		}
		const reader = new FileReader();
		reader.readAsText(file);
		api.onFileUpload(file).then((url) => {
			const image = {
				original: url,
				thumbnail: url,
			};
			setProp((props) => (props.images = [...props.images, image]));
		});
	};
	const uploaderId = `image-gallery-${id}`;
	return (
		<Box>
			<OptionTitle>Elenco immagini</OptionTitle>
			<StyledInput type="file" accept="image/*" id={uploaderId} onChange={onImageChange} />
			<label htmlFor={uploaderId}>
				<Button variant="contained" color="primary" component="span" startIcon={<PublishIcon />}>
					Carica
				</Button>
			</label>
			<ImagesList>
				{images.map((img, index) => (
					<ImageListItem>
						<img src={img.original} alt="" />
						<DeleteImageButton onClick={() => onImageRemove(index)} />
					</ImageListItem>
				))}
			</ImagesList>
		</Box>
	);
};
export default ImageGallerySettings;
