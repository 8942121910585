import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import Wrapper from '../wrapper';
import YouTubeSettings from './settings';

const ResponsiveBox = styled(Box)`
	height: 0;
	width: 100%;
	padding-bottom: 56.25%;
	& > iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
`;

const YouTubeVideo = ({ source }) => {
	const {
		connectors: { connect, drag },
	} = useNode();
	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper>
				{source ? (
					<ResponsiveBox width="100%" dangerouslySetInnerHTML={{ __html: source }} />
				) : (
					<Typography p={2}>Incorpora il video da YouTube tramite le impostazioni a lato</Typography>
				)}
			</Wrapper>
		</div>
	);
};

YouTubeVideo.craft = {
	name: 'YouTube',
	props: {
		source: null,
	},
	related: {
		settings: YouTubeSettings,
	},
};

export default YouTubeVideo;
