import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import styled from 'styled-components';

import ImageSize from '../../controls/imageSize';
import { OptionTitle } from '../../controls/styled';
import TextInputField from '../../controls/textField';
import { api } from '../../editor/api';

const StyledInput = styled.input`
	display: none;
`;

const ImageSettings = () => {
	const {
		id,
		actions: { setProp },
		alt,
		src,
		caption,
		width,
	} = useNode((node) => ({
		alt: node.data.props.alt,
		src: node.data.props.src,
		caption: node.data.props.caption,
		width: node.data.props.width,
	}));
	const setCaption = (e) => {
		setProp((props) => (props.caption = e.target.value));
	};
	const setAlt = (e) => {
		setProp((props) => (props.alt = e.target.value));
	};
	const setWidth = (_, value) => {
		setProp((props) => (props.width = value));
	};
	const onImageChange = (e) => {
		const file = e.target.files[0];
		const maxAllowedSize = 5 * 1024 * 1024;
		if (file.size > maxAllowedSize) {
			alert('Maximum file size is 5MB');
			e.target.value = '';
			return;
		}
		const reader = new FileReader();
		reader.readAsText(file);
		api.onFileUpload(file).then((url) => {
			console.log(`[Editor] url: ${url}`);
			setProp((props) => (props.src = url));
		});
	};
	const uploaderId = `image-${id}`;
	return (
		<Box>
			<OptionTitle>Immagine</OptionTitle>
			<StyledInput type="file" accept="image/*" id={uploaderId} onChange={onImageChange} />
			<label htmlFor={uploaderId}>
				<Button variant="contained" color="primary" component="span" startIcon={<PublishIcon />}>
					Carica
				</Button>
			</label>
			<Box height="16px" />
			<OptionTitle>Didascalia</OptionTitle>
			<TextInputField onChange={setCaption} value={caption} />
			<OptionTitle>Testo alternativo</OptionTitle>
			<TextInputField onChange={setAlt} value={alt} />
			<OptionTitle>Grandezza immagine</OptionTitle>
			<ImageSize value={width} onChange={setWidth} />
		</Box>
	);
};
export default ImageSettings;
