import { useNode } from '@craftjs/core';

import ControlWrapper from '../../controls/_wrapper';
import Colors from '../../controls/colors';
import Margins from '../../controls/margins';
import NumberInput from '../../controls/numberInput';

const DividerSettings = () => {
	const {
		actions: { setProp },
		color,
		size,
		paddingTop,
		paddingLeft,
		paddingBottom,
		paddingRight,
	} = useNode((node) => ({
		color: node.data.props.color,
		size: node.data.props.size,
		paddingTop: node.data.props.paddingTop,
		paddingLeft: node.data.props.paddingLeft,
		paddingBottom: node.data.props.paddingBottom,
		paddingRight: node.data.props.paddingRight,
	}));
	const setColor = (color) => {
		const {
			rgb: { r, g, b, a },
		} = color;
		setProp((props) => (props.color = `rgba(${r}, ${g}, ${b}, ${a})`));
	};
	const setSize = (_, value) => setProp((props) => (props.size = value));
	const setPaddingTop = (_, value) => setProp((props) => (props.paddingTop = value));
	const setPaddingRight = (_, value) => setProp((props) => (props.paddingRight = value));
	const setPaddingBottom = (_, value) => setProp((props) => (props.paddingBottom = value));
	const setPaddingLeft = (_, value) => setProp((props) => (props.paddingLeft = value));
	return (
		<>
			<ControlWrapper title="Colore">
				<Colors onChange={setColor} value={color} label="Colore testo" />
			</ControlWrapper>
			<ControlWrapper title="Dimensione">
				<NumberInput min={1} max={16} step={1} value={size} onChange={setSize} />
			</ControlWrapper>
			<Margins
				top={paddingTop}
				bottom={paddingBottom}
				left={paddingLeft}
				right={paddingRight}
				setTop={setPaddingTop}
				setBottom={setPaddingBottom}
				setLeft={setPaddingLeft}
				setRight={setPaddingRight}
			/>
		</>
	);
};

export default DividerSettings;
