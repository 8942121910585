/* eslint-disable no-underscore-dangle */

class Api {
	init(onFileUpload) {
		this.onFileUpload = onFileUpload;
	}

	get onFileUpload() {
		return this._onFileUpload;
	}

	set onFileUpload(func) {
		this._onFileUpload = func;
	}
}

export const api = new Api();
