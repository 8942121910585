import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Slider from '@material-ui/core/Slider';
import styled from 'styled-components';

const StyledInput = styled(Input)`
	width: 64px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	& > input {
		padding: 4px 0 4px 8px;
	}
`;

const NumberInput = ({ value, onChange, step, min, max, input = true, marks = false }) => {
	const onInputChange = (e) => {
		onChange(e, e.target.value);
	};
	return (
		<Grid container spacing={2} alignItems="">
			<Grid item xs>
				<Slider
					value={value}
					step={step}
					min={min}
					max={max}
					valueLabelDisplay="auto"
					onChange={onChange}
					track={false}
					marks={marks}
				/>
			</Grid>
			{!!input && (
				<Grid item>
					<StyledInput
						variant="outlined"
						type="number"
						value={value}
						margin="dense"
						onChange={onInputChange}
						inputProps={{
							step,
							min,
							max,
							type: 'number',
						}}
						disableUnderline
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default NumberInput;
