import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import styled from 'styled-components';

const StyledToggleButton = styled(ToggleButton)`
	border: none;
	width: 64px;
`;

const ImageSize = ({ value, onChange }) => (
	<ToggleButtonGroup size="small" value={value} exclusive onChange={onChange}>
		<StyledToggleButton value="33%">
			<PhotoSizeSelectActualIcon fontSize="small" />
		</StyledToggleButton>
		<StyledToggleButton value="66%">
			<PhotoSizeSelectActualIcon fontSize="medium" />
		</StyledToggleButton>
		<StyledToggleButton value="100%">
			<PhotoSizeSelectActualIcon fontSize="large" />
		</StyledToggleButton>
	</ToggleButtonGroup>
);

export default ImageSize;
