import { useEditor, useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import { defaultStyles, FileIcon } from 'react-file-icon';
import styled from 'styled-components';

import Wrapper from '../wrapper';
import FileSettings from './settings';

const Container = styled(Box)`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 16px 24px;
	border-radius: 8px;
	gap: 24px;
`;

const LeftContainer = styled.div`
	max-width: 48px;
	margin-top: 6px;

	& > svg {
		filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.12));
	}
`;

const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	// button is rendered as an anchor element
	& > a {
		width: max-content;
	}
`;

const File = ({ src, description }) => {
	const {
		connectors: { connect, drag },
	} = useNode();
	const { enabled: isEditorEnabled } = useEditor((state) => ({ enabled: state.options.enabled }));

	const extension = src.split('?')[0].split('.').pop();
	const content = (
		<Container bgcolor={colors.grey[100]}>
			<LeftContainer>
				<FileIcon extension={extension} {...defaultStyles[extension]} />
			</LeftContainer>
			<RightContainer>
				{description ? <Typography variant="h6">{description}</Typography> : null}
				<Button
					href={src}
					component="a"
					disabled={!src}
					target="_blank"
					rel="noopener noreferrer"
					startIcon={<GetAppIcon />}
					variant="outlined"
					color="primary"
					size="small"
				>
					Download
				</Button>
			</RightContainer>
		</Container>
	);

	return isEditorEnabled ? (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper>{content}</Wrapper>
		</div>
	) : (
		content
	);
};

File.craft = {
	name: 'File',
	props: {
		src: '',
		description: '',
	},
	related: {
		settings: FileSettings,
	},
};

export default File;
