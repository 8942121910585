import { useEditor, useNode } from '@craftjs/core';
import * as colors from '@material-ui/core/colors';

const Container = ({ children }) => {
	const {
		connectors: { connect },
	} = useNode();
	const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }));
	return (
		<div border={enabled ? `1px dotted ${colors.blueGrey[100]}` : 'none'} ref={connect}>
			{children}
		</div>
	);
};

Container.craft = {
	rules: {
		canMoveIn: (incomingNode) => true,
	},
};

export default Container;
