import styled from 'styled-components';

export const Expandable = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 8px;
	margin-bottom: 8px;
`;

export const ExpandableContent = styled.div`
	max-height: ${({ height }) => (height != null ? `${height}px` : 'unset')};
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
`;

export const Expander = styled.div`
	text-align: center;
`;

export const Editable = styled.div`
	display: flex;
	gap: 8px;

	> :last-child {
		/* cannot set this directly on the Element component */
		width: 100%;
	}
`;

export const StripedBlock = styled.div`
	min-width: 12px;
	min-height: 60px;
	background: repeating-linear-gradient(45deg, transparent, transparent 10px, gray 10px, gray 24px);
	border: 1px solid gray;
`;
