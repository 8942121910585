import Grid from '@material-ui/core/Grid';

import ControlWrapper from './_wrapper';
import NumberInput from './numberInput';
import Selector from './selector';
import { OptionTitle } from './styled';
import TextAlignment from './textAlign';

const TypographySettings = ({
	setAlignment,
	textAlign,
	setFontFamily,
	fontFamily,
	fontList,
	setFontSize,
	fontSize,
	setFontWeight,
	fontWeight,
}) => (
	<ControlWrapper title="Tipografia">
		<Grid container spacing={2}>
			<Grid item lg={12}>
				<OptionTitle>Allineamento</OptionTitle>
				<TextAlignment onChange={setAlignment} value={textAlign} />
			</Grid>
			<Grid item lg={12}>
				<OptionTitle>Carattere</OptionTitle>
				<Selector onChange={setFontFamily} value={fontFamily} items={fontList} />
			</Grid>
			<Grid item lg={12} xl={6}>
				<OptionTitle>Grandezza</OptionTitle>
				<NumberInput onChange={setFontSize} value={fontSize} min={8} max={96} step={2} />
			</Grid>
			<Grid item lg={12} xl={6}>
				<OptionTitle>Peso</OptionTitle>
				<NumberInput onChange={setFontWeight} value={fontWeight} min={100} max={900} step={100} marks />
			</Grid>
		</Grid>
	</ControlWrapper>
);

export default TypographySettings;
