import { Element, useEditor, useNode } from '@craftjs/core';
import Grid from '@material-ui/core/Grid';

import Container from '../../container';
import Text from '../../text';
import Wrapper from '../../wrapper';
import TwoColumnsSettings from './settings';

const TwoColumns = ({ size, spacing }) => {
	const {
		connectors: { connect, drag },
		isActive,
		actions: { setProp },
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));
	const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }));
	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper>
				<Grid container spacing={spacing}>
					<Grid item xs={size}>
						<Element id="2_col_1" is={Container} canvas>
							<Text />
						</Element>
					</Grid>
					<Grid item xs={12 - size}>
						<Element id="2_col_2" is={Container} canvas>
							<Text />
						</Element>
					</Grid>
				</Grid>
			</Wrapper>
		</div>
	);
};

TwoColumns.craft = {
	displayName: 'Due Colonne',
	props: {
		spacing: 2,
		size: 6,
	},
	related: {
		settings: TwoColumnsSettings,
	},
};

export default TwoColumns;
