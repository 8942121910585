import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import { SettingsPanel } from './settings';
import { Toolbox } from './toolbox';

const Container = styled(Box)`
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	text-align: left;
	white-space: nowrap;

	@media (max-width: 1024px) {
		gap: 8px;
	}

	> :nth-child(2) {
		flex-grow: 1;
	}
`;

const SideBar = () => (
	<Container>
		<Toolbox />
		<SettingsPanel />
	</Container>
);

export default SideBar;
