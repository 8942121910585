import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const Title = styled(Typography)`
	font-size: 1rem !important;
	color: ${colors.blueGrey[700]};
`;

export const Hint = styled(Typography)`
	font-size: 0.5rem !important;
	color: ${colors.blueGrey[300]};
	text-transform: uppercase;
`;
