import { Element, useNode } from '@craftjs/core';
import Grid from '@material-ui/core/Grid';

import Container from '../../container';
import Text from '../../text';
import Wrapper from '../../wrapper';
import TwoColumnsSettings from '../twoColumns/settings';

const ThreeColumns = ({ size, spacing }) => {
	const {
		connectors: { connect, drag },
		isActive,
		actions: { setProp },
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));

	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper active={isActive}>
				<Grid container spacing={spacing}>
					<Grid item xs={size[0]}>
						<Element id="3_col_1" is={Container} canvas>
							<Text />
						</Element>
					</Grid>
					<Grid item xs={size[1]}>
						<Element id="3_col_2" is={Container} canvas>
							<Text />
						</Element>
					</Grid>
					<Grid item xs={12 - size[0] - size[1]}>
						<Element id="3_col_3" is={Container} canvas>
							<Text />
						</Element>
					</Grid>
				</Grid>
			</Wrapper>
		</div>
	);
};

ThreeColumns.craft = {
	displayName: 'Tre Colonne',
	props: {
		spacing: 2,
		size: [4, 4],
	},
	related: {
		settings: TwoColumnsSettings,
	},
};

export default ThreeColumns;
