import { useNode } from '@craftjs/core';

import Wrapper from '../wrapper';
import AudioSettings from './settings';

const Audio = ({ src }) => {
	const {
		connectors: { connect, drag },
	} = useNode();
	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper>
				<audio controls>
					<source src={src} type="audio/mpeg" />
					Your browser does not support the audio element.
				</audio>
			</Wrapper>
		</div>
	);
};

Audio.craft = {
	name: 'Audio',
	props: {
		src: ' ',
	},
	related: {
		settings: AudioSettings,
	},
};

export default Audio;
