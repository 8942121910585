import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import styled from 'styled-components';

import { OptionTitle } from '../../controls/styled';
import { api } from '../../editor/api';

const StyledInput = styled.input`
	display: none;
`;

const VideoSettings = () => {
	const {
		id,
		actions: { setProp },
	} = useNode();
	const onMediaChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.readAsText(file);
		api.onFileUpload(file).then((url) => {
			console.log(`[Editor] url: ${url}`);
			setProp((props) => (props.src = url));
		});
	};
	const uploaderId = `video-${id}`;
	return (
		<Box>
			<OptionTitle>File Video</OptionTitle>
			<StyledInput type="file" accept="video/*" id={uploaderId} onChange={onMediaChange} />
			<label htmlFor={uploaderId}>
				<Button variant="contained" color="primary" component="span" startIcon={<PublishIcon />}>
					Carica
				</Button>
			</label>
		</Box>
	);
};

export default VideoSettings;
