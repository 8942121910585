import { useEditor } from '@craftjs/core';
import { useCallback, useEffect } from 'react';

const Events = () => {
	const { query } = useEditor((state) => ({
		enabled: state.options.enabled,
	}));
	const sendEditorData = useCallback(() => {
		const event = new CustomEvent('editor-data', { detail: query.serialize() });
		window.dispatchEvent(event);
	}, [query]);
	useEffect(() => {
		window.addEventListener('save-editor', sendEditorData);
		return () => window.removeEventListener('save-editor', sendEditorData);
	}, [sendEditorData]);
	return null;
};

export default Events;
