import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const TextAlignment = ({ onChange, value }) => (
	<ToggleButtonGroup value={value} exclusive onChange={onChange} size="small">
		<ToggleButton value="left">
			<FormatAlignLeftIcon />
		</ToggleButton>
		<ToggleButton value="center">
			<FormatAlignCenterIcon />
		</ToggleButton>
		<ToggleButton value="right">
			<FormatAlignRightIcon />
		</ToggleButton>
		<ToggleButton value="justify">
			<FormatAlignJustifyIcon />
		</ToggleButton>
	</ToggleButtonGroup>
);

export default TextAlignment;
