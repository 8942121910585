import { useNode } from '@craftjs/core';
import Grid from '@material-ui/core/Grid';

import ControlWrapper from '../../controls/_wrapper';
import Colors from '../../controls/colors';
import NumberInput from '../../controls/numberInput';
import { OptionTitle } from '../../controls/styled';

const PageSettings = () => {
	const {
		backgroundColor,
		paddingTop,
		paddingLeft,
		paddingBottom,
		paddingRight,
		actions: { setProp },
	} = useNode((node) => ({
		backgroundColor: node.data.props.backgroundColor,
		paddingTop: node.data.props.paddingTop,
		paddingLeft: node.data.props.paddingLeft,
		paddingBottom: node.data.props.paddingBottom,
		paddingRight: node.data.props.paddingRight,
	}));
	const onColorChange = (color) => {
		const {
			rgb: { r, g, b, a },
		} = color;
		setProp((props) => (props.backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`));
	};

	const onPaddingTopChange = (_, value) => setProp((props) => (props.paddingTop = value));
	const onPaddingRightChange = (_, value) => setProp((props) => (props.paddingRight = value));
	const onPaddingBottomChange = (_, value) => setProp((props) => (props.paddingBottom = value));
	const onPaddingLeftChange = (_, value) => setProp((props) => (props.paddingLeft = value));

	return (
		<div>
			<ControlWrapper title="Colore di sfondo">
				<Colors onChange={onColorChange} value={backgroundColor} />
			</ControlWrapper>
			<ControlWrapper title="Margini">
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<OptionTitle>Margine superiore</OptionTitle>
						<NumberInput min={0} max={96} step={4} value={paddingTop} onChange={onPaddingTopChange} />
					</Grid>
					<Grid item xs={6}>
						<OptionTitle>Margine inferiore</OptionTitle>
						<NumberInput
							title="Margine Inferiore"
							min={0}
							max={96}
							step={4}
							value={paddingBottom}
							onChange={onPaddingBottomChange}
						/>
					</Grid>
					<Grid item xs={6}>
						<OptionTitle>Margine sinistro</OptionTitle>
						<NumberInput
							title="Margine Sinistro"
							min={0}
							max={96}
							step={4}
							value={paddingLeft}
							onChange={onPaddingLeftChange}
						/>
					</Grid>
					<Grid item xs={6}>
						<OptionTitle>Margine destro</OptionTitle>
						<NumberInput
							title="Margine Destro"
							min={0}
							max={96}
							step={4}
							value={paddingRight}
							onChange={onPaddingRightChange}
						/>
					</Grid>
				</Grid>
			</ControlWrapper>
		</div>
	);
};

export default PageSettings;
