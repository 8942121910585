import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
	width: 100%;
`;

const Selector = ({ value, items, onChange }) => (
	<StyledSelect value={value} onChange={onChange}>
		{items.map((item) => (
			<MenuItem value={item.value}>{item.label}</MenuItem>
		))}
	</StyledSelect>
);

export default Selector;
