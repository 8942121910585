import { useEditor, useNode } from '@craftjs/core';
import Typography from '@material-ui/core/Typography';
import ContentEditable from 'react-contenteditable';

import Wrapper from '../wrapper';
import HeadingSettings from './settings';

const Heading = ({ as, color, text, textAlign }) => {
	const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }));
	const {
		connectors: { connect, drag },
		isActive,
		actions: { setProp },
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));
	return enabled ? (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper active={isActive}>
				<ContentEditable
					html={text}
					onChange={(e) => setProp((props) => (props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, '')))} // TODO: rimuovi i tag eccetto br
					tagName={as}
					style={{ textAlign, color }}
				/>
			</Wrapper>
		</div>
	) : (
		<Typography variant={as} style={{ textAlign, color }}>
			{text}
		</Typography>
	);
};

Heading.craft = {
	displayName: 'Titoli',
	props: {
		as: 'h1',
		text: 'Titolo',
		textAlign: 'left',
		color: 'rgba(0, 0, 0, 1)',
	},
	related: {
		settings: HeadingSettings,
	},
};

export default Heading;
