import { useEditor, useNode } from '@craftjs/core';

import Wrapper from '../wrapper';
import LinkSettings from './settings';

const Link = ({
	color,
	title,
	href,
	fontSize,
	textAlign,
	fontWeight,
	paddingTop,
	paddingLeft,
	paddingBottom,
	paddingRight,
	fontFamily,
}) => {
	const {
		connectors: { connect, drag },
		isActive,
		actions: { setProp },
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));
	const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }));
	const style = {
		display: 'block',
		paddingTop,
		paddingLeft,
		paddingBottom,
		paddingRight,
		textAlign,
		color,
		fontWeight,
		fontSize: `${fontSize}px`,
		fontFamily,
		textDecoration: 'none',
	};
	return enabled ? (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper active={isActive}>
				<a href={href} target="_blank" rel="noopener noreferrer" title={title} style={style}>
					{title}
				</a>
			</Wrapper>
		</div>
	) : (
		<a href={href} target="_blank" rel="noopener noreferrer" title={title} style={style}>
			{title}
		</a>
	);
};

Link.craft = {
	displayName: 'Link',
	props: {
		title: 'Titolo',
		href: '',
		fontSize: 16,
		textAlign: 'left',
		color: 'rgba(0, 0, 0, 1)',
		fontWeight: '500',
		paddingTop: 8,
		paddingLeft: 8,
		paddingBottom: 8,
		paddingRight: 8,
		fontFamily: 'Poppins',
	},
	related: {
		settings: LinkSettings,
	},
};

export default Link;
