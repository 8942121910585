import { useNode } from '@craftjs/core';
import Grid from '@material-ui/core/Grid';

import ControlWrapper from '../../../controls/_wrapper';
import NumberInput from '../../../controls/numberInput';
import { OptionTitle } from '../../../controls/styled';

const TwoColumnsSettings = () => {
	const {
		actions: { setProp },
		spacing,
		size,
	} = useNode((node) => ({
		size: node.data.props.size,
		spacing: node.data.props.spacing,
	}));
	const setSize = (_, value) => {
		if (value.length > 1) {
			if (value[0] + value[1] > 11) {
				value[1] = 11 - value[0];
			}
		}
		setProp((props) => (props.size = value));
	};
	const setSpacing = (_, value) => {
		setProp((props) => (props.spacing = value));
	};
	return (
		<ControlWrapper title="Layout">
			<Grid container spacing={2}>
				<Grid item lg={12} xl={6}>
					<OptionTitle>Spaziatura colonne</OptionTitle>
					<NumberInput onChange={setSpacing} value={spacing} min={0} max={4} step={1} input={false} />
				</Grid>
				<Grid item lg={12} xl={6}>
					<OptionTitle>Bilanciamento colonne</OptionTitle>
					<NumberInput onChange={setSize} value={size} min={1} max={11} step={1} input={false} />
				</Grid>
			</Grid>
		</ControlWrapper>
	);
};

export default TwoColumnsSettings;
