import 'quill/dist/quill.snow.css';
import './style.css';

import { useEditor, useNode } from '@craftjs/core';
import { useEffect } from 'react';
import { useQuill } from 'react-quilljs';

import Wrapper from '../wrapper';
import RichTextEditorSettings from './settings';

const RichTextEditor = ({ content, paddingTop, paddingLeft, paddingBottom, paddingRight }) => {
	const modules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			[{ align: [] }],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ size: ['small', false, 'large', 'huge'] }],
			['link'],
			[{ color: [] }, { background: [] }],
			['clean'],
		],
		clipboard: {
			matchVisual: false,
		},
	};
	const formats = [
		'bold',
		'italic',
		'underline',
		'strike',
		'align',
		'list',
		'indent',
		'size',
		'link',
		'color',
		'background',
		'clean',
	];
	const {
		connectors: { connect, drag },
		isActive,
		actions: { setProp },
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));

	const { quill, quillRef } = useQuill({ formats, modules });
	useEffect(() => {
		if (quill) {
			quill.clipboard.dangerouslyPasteHTML(content);
			const updateContent = () => {
				setProp((props) => {
					props.content = quill?.root?.innerHTML;
				});
			};
			quill.on('text-change', updateContent);
			return () => quill.off('text-change', updateContent);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quill]);

	const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }));
	const style = {
		paddingTop,
		paddingLeft,
		paddingBottom,
		paddingRight,
	};
	return enabled ? (
		<div ref={(ref) => connect(drag(ref))} style={style}>
			<Wrapper active={isActive}>
				<div ref={quillRef} />
			</Wrapper>
		</div>
	) : (
		// eslint-disable-next-line react/no-danger
		<div dangerouslySetInnerHTML={{ __html: content }} className="ql-snow ql-viewer" style={style} />
	);
};

RichTextEditor.craft = {
	displayName: 'Editor Classico',
	props: {
		content: '',
		paddingTop: 8,
		paddingLeft: 8,
		paddingBottom: 8,
		paddingRight: 8,
	},
	related: {
		settings: RichTextEditorSettings,
	},
};

export default RichTextEditor;
