import 'react-image-gallery/styles/css/image-gallery.css';

import { Editor, Element, Frame } from '@craftjs/core';
import Paper from '@material-ui/core/Paper';
import { useMemo } from 'react';
import styled from 'styled-components';

import Audio from './components/blocks/audio';
import Container from './components/blocks/container';
import Divider from './components/blocks/divider';
import File from './components/blocks/file';
import Heading from './components/blocks/heading';
import Image from './components/blocks/image';
import ImageGallery from './components/blocks/image-gallery';
import ShowMore from './components/blocks/layouts/show-more';
import ThreeColumns from './components/blocks/layouts/threeColumns';
import TwoColumns from './components/blocks/layouts/twoColumns';
import Link from './components/blocks/link';
import Page from './components/blocks/page';
import RichTextEditor from './components/blocks/rich-text-editor';
import Text from './components/blocks/text';
import Video from './components/blocks/video';
import YouTubeVideo from './components/blocks/youtube';
import { api } from './components/editor/api';
import Events from './components/editor/event';
import SideBar from './components/editor/sidebar';
import Scrollable from './components/scrollable';

const PageViewerContainer = styled(Paper)`
	position: absolute;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
`;

const PageEditorContainer = styled(PageViewerContainer)`
	display: flex;
	gap: 16px;
	padding: 4px;
`;

const resolver = {
	Audio,
	Container,
	Divider,
	File,
	Heading,
	Image,
	ImageGallery,
	Link,
	Page,
	RichTextEditor,
	Text,
	ThreeColumns,
	TwoColumns,
	ShowMore,
	Video,
	YoutubeVideo: YouTubeVideo, // todo: fix dopo demo
};

export const PageEditor = ({ json }) => (
	<PageEditorContainer>
		<Editor resolver={resolver}>
			<Events />
			<Scrollable>
				<Frame json={json}>
					<Element is={Page} id="main" canvas>
						<Text size="small" text="Hi world!" />
					</Element>
				</Frame>
			</Scrollable>
			<SideBar />
		</Editor>
	</PageEditorContainer>
);

export const PageViewer = ({ json, after }) => {
	const backgroundColor = useMemo(() => {
		if (!json) return null;
		const root = JSON.parse(json).ROOT;

		return root?.props?.backgroundColor;
	}, [json]);

	return (
		<PageViewerContainer>
			<Editor resolver={resolver} enabled={false}>
				<Scrollable backgroundColor={backgroundColor}>
					<Frame json={json} />
					{after ?? null}
				</Scrollable>
			</Editor>
		</PageViewerContainer>
	);
};

export const editorApi = api;
