import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const OptionTitle = styled(Typography)`
	font-size: 0.6rem !important;
	text-transform: uppercase;
	color: ${colors.blueGrey[300]};
	font-weight: bold;
	padding-bottom: 8px;
`;
