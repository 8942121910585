import { SET_MAP_CATEGORIES, SET_MAP_FILTERS, SET_MAP_INDEPTHS, SET_MAP_ITEMS, SET_MAP_RELATED } from '../../constants';
import { IAction, IReducerMap } from '../../types';

const initialState: IReducerMap = {
	categories: [],
	filters: [],
	indepths: [],
	items: [],
	related: [],
};

export default function data(state = initialState, action: IAction) {
	switch (action.type) {
		case SET_MAP_CATEGORIES: {
			return { ...state, categories: action.payload };
		}
		case SET_MAP_FILTERS: {
			return { ...state, filters: action.payload };
		}
		case SET_MAP_INDEPTHS: {
			return { ...state, indepths: action.payload };
		}
		case SET_MAP_ITEMS: {
			return { ...state, items: action.payload };
		}
		case SET_MAP_RELATED: {
			return { ...state, related: action.payload };
		}
		default:
			return state;
	}
}
