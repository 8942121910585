import { useEditor, useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import * as colors from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import styled from 'styled-components';

const WrapperStyle = styled.div`
	box-sizing: border-box;
	border-style: dashed;
	border-width: 1px;
	border-color: ${({ active, hover }) => {
		if (active) {
			return colors.blue[400];
		}
		if (hover) {
			return colors.blueGrey[200];
		}
		return 'transparent';
	}};
`;

const IndicatorDiv = styled.div`
	position: absolute;
	top: 1px;
	right: 0;
	width: min-content;
	height: 32px;
	font-size: 12px;
	line-height: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ffffff88;
	border-left: 1px dashed ${colors.blueGrey[300]};
	border-right: 1px dashed ${colors.blueGrey[300]};
	border-bottom: 1px dashed ${colors.blueGrey[300]};
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 9999;
	& > button {
		width: 32px;
		height: 32px;
	}
	svg {
		fill: ${colors.blueGrey[500]};
		width: 16px;
		height: 16px;
	}
`;

const Title = styled.p`
	width: 100px;
	color: ${colors.blueGrey[500]};
	flex: 1;
	margin: 0 4px;
`;

const DeleteIconButton = styled(IconButton)`
	svg {
		fill: #900;
	}
`;

const Wrapper = ({ children }) => {
	const { actions, query } = useEditor();
	const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }));
	const {
		id,
		isActive,
		isHover,
		dom,
		name,
		moveable,
		deletable,
		connectors: { drag },
	} = useNode((node) => ({
		isActive: node.events.selected,
		isHover: node.events.hovered,
		dom: node.dom,
		name: node.data.custom.displayName || node.data.displayName,
		moveable: query.node(node.id).isDraggable(),
		deletable: query.node(node.id).isDeletable(),
		parent: node.data.parent,
		props: node.data.props,
	}));

	return (
		<Box position="relative">
			{enabled && (isHover || isActive) ? (
				<IndicatorDiv ref={dom}>
					<Title>{name}</Title>
					<Box display="flex">
						{moveable ? (
							<IconButton ref={drag}>
								<OpenWithIcon />
							</IconButton>
						) : null}
						{deletable ? (
							<Tooltip title="Elimina">
								<DeleteIconButton
									onMouseDown={(e) => {
										e.stopPropagation();
										actions.delete(id);
									}}
								>
									<DeleteIcon />
								</DeleteIconButton>
							</Tooltip>
						) : null}
					</Box>
				</IndicatorDiv>
			) : null}
			<WrapperStyle active={enabled && isActive} hover={enabled && isHover}>
				{children}
			</WrapperStyle>
		</Box>
	);
};

export default Wrapper;
