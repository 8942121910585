import Box from '@material-ui/core/Box';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';

const StyledBox = styled(Box)`
	width: min-content;
	margin: 16px auto 0;
	& > .sketch-picker {
		box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px !important;
	}
`;

const Colors = ({ value, onChange, label }) => (
	<StyledBox>
		<SketchPicker color={value} onChange={onChange} />
	</StyledBox>
);

export default Colors;
