import { useNode } from '@craftjs/core';
import styled from 'styled-components';

import PageSettings from './settings';

const Container = styled.div`
	width: 100%;
	height: min-content;
	background-color: ${({ backgroundColor }) => backgroundColor};
	padding-top: ${({ paddingTop }) => paddingTop}px;
	padding-left: ${({ paddingLeft }) => paddingLeft}px;
	padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
	padding-right: ${({ paddingRight }) => paddingRight}px;
	box-sizing: border-box;
`;

const Page = ({ children, ...props }) => {
	const {
		connectors: { connect, drag },
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));
	return (
		<Container ref={(ref) => connect(drag(ref))} {...props}>
			{children}
		</Container>
	);
};

Page.craft = {
	displayName: 'Pagina',
	props: {
		backgroundColor: '#fefefe',
		paddingTop: 8,
		paddingLeft: 8,
		paddingBottom: 8,
		paddingRight: 8,
	},
	related: {
		settings: PageSettings,
	},
	rules: {
		canMoveIn: (incomingNode) => true,
		canDrag: (node) => false,
	},
};

export default Page;
