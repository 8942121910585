import { useNode } from '@craftjs/core';

import Wrapper from '../wrapper';
import VideoSettings from './settings';

const Video = ({ src }) => {
	const {
		connectors: { connect, drag },
	} = useNode();
	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper>
				<video controls>
					<source src={src} type="video/*" />
					Your browser does not support the video element.
				</video>
			</Wrapper>
		</div>
	);
};

Video.craft = {
	name: 'Video',
	props: {
		src: '',
	},
	related: {
		settings: VideoSettings,
	},
};

export default Video;
