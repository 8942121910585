import { useNode } from '@craftjs/core';
import styled from 'styled-components';

import Wrapper from '../wrapper';
import DividerSettings from './settings';

const DividerBox = styled.div`
	width: 100%;
	height: ${(props) => props.size}px;
	background-color: ${(props) => props.color};
`;

const DividerWrapper = styled.div`
	padding-top: ${(props) => props.paddingTop}px;
	padding-right: ${(props) => props.paddingRight}px;
	padding-bottom: ${(props) => props.paddingBottom}px;
	padding-left: ${(props) => props.paddingLeft}px;
`;

const Divider = ({ color, size, paddingTop, paddingRight, paddingBottom, paddingLeft }) => {
	const {
		connectors: { connect, drag },
		isActive,
	} = useNode((node) => ({
		isActive: node.events.selected,
	}));

	return (
		<div ref={(ref) => connect(drag(ref))}>
			<Wrapper>
				<DividerWrapper
					paddingTop={paddingTop}
					paddingRight={paddingRight}
					paddingBottom={paddingBottom}
					paddingLeft={paddingLeft}
				>
					<DividerBox color={color} size={size} />
				</DividerWrapper>
			</Wrapper>
		</div>
	);
};

Divider.craft = {
	name: 'Divisore',
	props: { color: 'rgba(0, 0, 0, 1)', size: 3, paddingTop: 8, paddingLeft: 8, paddingBottom: 8, paddingRight: 8 },
	related: {
		settings: DividerSettings,
	},
};

export default Divider;
