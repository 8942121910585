import { SET_DATA } from '../../constants';
import { IAction, IReducerData } from '../../types';

const initialState: IReducerData = {
	data: null,
};

export default function data(state = initialState, action: IAction) {
	switch (action.type) {
		case SET_DATA: {
			return {
				...state,
				data: action.payload,
			};
		}
		default:
			return state;
	}
}
