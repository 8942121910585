import Grid from '@material-ui/core/Grid';

import ControlWrapper from './_wrapper';
import NumberInput from './numberInput';
import { OptionTitle } from './styled';

const Margins = ({ top, bottom, left, right, setTop, setBottom, setLeft, setRight }) => (
	<ControlWrapper title="Margini">
		<Grid container spacing={2}>
			<Grid item lg={12} xl={6}>
				<OptionTitle>Margine superiore</OptionTitle>
				<NumberInput min={0} max={96} step={4} value={top} onChange={setTop} />
			</Grid>
			<Grid item lg={12} xl={6}>
				<OptionTitle>Margine inferiore</OptionTitle>
				<NumberInput min={0} max={96} step={4} value={bottom} onChange={setBottom} />
			</Grid>
			<Grid item lg={12} xl={6}>
				<OptionTitle>Margine sinistro</OptionTitle>
				<NumberInput min={0} max={96} step={4} value={left} onChange={setLeft} />
			</Grid>
			<Grid item lg={12} xl={6}>
				<OptionTitle>Margine destro</OptionTitle>
				<NumberInput min={0} max={96} step={4} value={right} onChange={setRight} />
			</Grid>
		</Grid>
	</ControlWrapper>
);

export default Margins;
