import { useEditor } from '@craftjs/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import * as colors from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LinkIcon from '@material-ui/icons/Link';
import RemoveIcon from '@material-ui/icons/Remove';
import SubjectIcon from '@material-ui/icons/Subject';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import TitleIcon from '@material-ui/icons/Title';
import VideocamIcon from '@material-ui/icons/Videocam';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Audio from '../blocks/audio';
import Divider from '../blocks/divider';
import File from '../blocks/file';
import Heading from '../blocks/heading';
import Image from '../blocks/image';
import ImageGallery from '../blocks/image-gallery';
import ShowMore from '../blocks/layouts/show-more';
import ThreeColumns from '../blocks/layouts/threeColumns';
import TwoColumns from '../blocks/layouts/twoColumns';
import Link from '../blocks/link';
import RichTextEditor from '../blocks/rich-text-editor';
import Text from '../blocks/text';
import Video from '../blocks/video';
import YouTubeVideo from '../blocks/youtube';
import { Hint, Title } from './styled';

const BlockBox = styled(Box)`
	cursor: pointer;
	border: 1px solid transparent;
	text-align: center;
	transition: 0.2s;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 0;

	&:hover {
		background: rgba(0, 0, 0, 0.06);
		border: 1px solid rgba(0, 0, 0, 0.12);
	}
`;

const BlockTitle = styled(Typography)`
	width: 100%;
	font-size: 0.6rem !important;
	color: ${colors.blueGrey[400]};
	text-transform: uppercase;
	white-space: normal;
`;

const BlockItem = ({ refs, title, icon }) => (
	<BlockBox ref={(ref) => refs(ref)}>
		<Box textAlign="center">
			<Icon>{icon}</Icon>
		</Box>
		<BlockTitle>{title}</BlockTitle>
	</BlockBox>
);

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	gap: 16px 16px;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
`;
const StyledAccordion = styled(Accordion)`
	margin-bottom: 0 !important;
`;

const TwoColumnsIcon = styled(ViewAgendaIcon)`
	transform: rotate(90deg);
`;

export const Toolbox = () => {
	const [expanded, setExpanded] = useState(false);
	const { connectors, query, selected } = useEditor((state, query) => {
		const currentNodeId = state.events.selected;
		const selected = { id: currentNodeId };

		return { selected };
	});
	useEffect(() => {
		if (selected) {
			setExpanded(false);
		}
	}, [selected]);
	const onAccordionChange = (e, expanded) => {
		setExpanded(expanded);
	};
	return (
		<StyledAccordion expanded={expanded} onChange={onAccordionChange}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Box>
					<Title>Blocchi</Title>
					<Hint>Trascina per aggiungere</Hint>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<GridContainer>
					<BlockItem
						refs={(ref) => connectors.create(ref, <Heading text="Clicca qui per modificare il testo" />)}
						icon={<TitleIcon />}
						title="Titoli"
					/>
					<BlockItem
						refs={(ref) => connectors.create(ref, <Text text="Clicca qui per modificare il testo" />)}
						icon={<SubjectIcon />}
						title="Paragrafo"
					/>
					<BlockItem refs={(ref) => connectors.create(ref, <Link />)} icon={<LinkIcon />} title="Link" />
					<BlockItem refs={(ref) => connectors.create(ref, <Divider />)} icon={<RemoveIcon />} title="Divisore" />
					<BlockItem refs={(ref) => connectors.create(ref, <Image />)} s icon={<ImageIcon />} title="Immagine" />
					<BlockItem refs={(ref) => connectors.create(ref, <Audio />)} icon={<AudiotrackIcon />} title="Audio" />
					<BlockItem refs={(ref) => connectors.create(ref, <Video />)} icon={<VideocamIcon />} title="Video" />
					<BlockItem refs={(ref) => connectors.create(ref, <File />)} icon={<InsertDriveFileIcon />} title="File" />
					<BlockItem
						refs={(ref) => connectors.create(ref, <ImageGallery />)}
						icon={<ImageIcon />}
						title="Galleria Immagini"
					/>
					<BlockItem refs={(ref) => connectors.create(ref, <YouTubeVideo />)} icon={<YouTubeIcon />} title="Youtube" />
					<BlockItem
						refs={(ref) => connectors.create(ref, <TwoColumns />)}
						icon={<TwoColumnsIcon />}
						title="Due Colonne"
					/>

					<BlockItem
						refs={(ref) => connectors.create(ref, <ThreeColumns />)}
						icon={<ViewWeekIcon />}
						title="Tre Colonne"
					/>
					<BlockItem
						refs={(ref) => connectors.create(ref, <RichTextEditor />)}
						icon={<TextFieldsIcon />}
						title="Editor Classico"
					/>
					<BlockItem
						refs={(ref) => connectors.create(ref, <ShowMore />)}
						icon={<KeyboardArrowDownIcon />}
						title="Mostra di più"
					/>
				</GridContainer>
			</AccordionDetails>
		</StyledAccordion>
	);
};
