import { SET_QUERY } from '../../constants';
import { IAction, IReducerSearch } from '../../types';

const initialState: IReducerSearch = {
	query: '',
};

export default function search(state = initialState, action: IAction) {
	switch (action.type) {
		case SET_QUERY: {
			return {
				...state,
				query: action.payload,
			};
		}
		default:
			return state;
	}
}
