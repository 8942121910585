import * as colors from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useRef, useState } from 'react';
import styled from 'styled-components';

const OuterContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
`;

const InnerContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: auto;
	background: ${(props) => props.backgroundColor};
`;

const ScrollUpButton = styled(IconButton)`
	position: absolute;
	bottom: 20px;
	right: 40px;
	z-index: 100;
	background-color: white;
	box-shadow: 0 2px 2px 2px ${colors.grey[400]};
	transition: opacity 0.3s ease-in-out;

	&:hover {
		background-color: ${colors.grey[100]};
	}

	&.invisible {
		opacity: 0;
	}
`;

const Scrollable = ({ className, children, backgroundColor }) => {
	const [isButtonVisible, setIsButtonVisible] = useState(false);
	const containerRef = useRef();
	return (
		<OuterContainer className={className}>
			<InnerContainer
				ref={containerRef}
				backgroundColor={backgroundColor}
				onScroll={(e) => setIsButtonVisible(!!e.currentTarget.scrollTop)}>
				{children}
			</InnerContainer>
			<ScrollUpButton
				className={isButtonVisible ? undefined : 'invisible'}
				onClick={() => {
					containerRef.current.scrollTop = 0;
				}}
			>
				<KeyboardArrowUpIcon />
			</ScrollUpButton>
		</OuterContainer>
	);
};

export default Scrollable;
