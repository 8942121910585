import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';

import Margins from '../../controls/margins';

const RichTextEditorSettings = () => {
	const {
		actions: { setProp },
		paddingTop,
		paddingLeft,
		paddingBottom,
		paddingRight,
	} = useNode((node) => ({
		paddingTop: node.data.props.paddingTop,
		paddingLeft: node.data.props.paddingLeft,
		paddingBottom: node.data.props.paddingBottom,
		paddingRight: node.data.props.paddingRight,
	}));
	const setPaddingTop = (_, value) => setProp((props) => (props.paddingTop = value));
	const setPaddingRight = (_, value) => setProp((props) => (props.paddingRight = value));
	const setPaddingBottom = (_, value) => setProp((props) => (props.paddingBottom = value));
	const setPaddingLeft = (_, value) => setProp((props) => (props.paddingLeft = value));
	return (
		<Box>
			<Margins
				top={paddingTop}
				bottom={paddingBottom}
				left={paddingLeft}
				right={paddingRight}
				setTop={setPaddingTop}
				setBottom={setPaddingBottom}
				setLeft={setPaddingLeft}
				setRight={setPaddingRight}
			/>
		</Box>
	);
};

export default RichTextEditorSettings;
