import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const StyledMaterialTextField = styled(TextField)`
	width: 100%;
`;

const Container = styled(Box)`
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	padding: 8px;
	margin-bottom: 16px;
`;

const TextInputField = ({ value, onChange }) => (
	<Container>
		<StyledMaterialTextField multiline rows={2} value={value} onChange={onChange} />
	</Container>
);

export default TextInputField;
