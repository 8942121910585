import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

export const DeleteLogoButton = styled(IconButton)`
	position: absolute !important;
	top: 8px;
	right: 8px;
	color: red !important;
	background: rgba(255, 255, 255, 0.8) !important;
	padding: 4px !important;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;

	&:hover {
		background: white;
	}

	svg {
		font-size: 0.8rem;
	}
`;
