import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import ControlWrapper from '../../controls/_wrapper';
import Colors from '../../controls/colors';
import Margins from '../../controls/margins';
import { OptionTitle } from '../../controls/styled';
import TypographySettings from '../../controls/typography';

const LinkSettings = () => {
	const {
		actions: { setProp },
		title,
		href,
		fontSize,
		textAlign,
		color,
		fontWeight,
		paddingTop,
		paddingLeft,
		paddingBottom,
		paddingRight,
		fontFamily,
	} = useNode((node) => ({
		title: node.data.props.title,
		href: node.data.props.href,
		fontSize: node.data.props.fontSize,
		textAlign: node.data.props.textAlign,
		color: node.data.props.color,
		fontWeight: node.data.props.fontWeight,
		padding: node.data.props.padding,
		paddingTop: node.data.props.paddingTop,
		paddingLeft: node.data.props.paddingLeft,
		paddingBottom: node.data.props.paddingBottom,
		paddingRight: node.data.props.paddingRight,
		fontFamily: node.data.props.fontFamily,
	}));
	const setColor = (color) => {
		const {
			rgb: { r, g, b, a },
		} = color;
		setProp((props) => (props.color = `rgba(${r}, ${g}, ${b}, ${a})`));
	};
	const setAlignment = (_, alignment) => setProp((props) => (props.textAlign = alignment));
	const setFontSize = (_, value) => setProp((props) => (props.fontSize = value));
	const setFontWeight = (_, value) => setProp((props) => (props.fontWeight = value));
	const setPaddingTop = (_, value) => setProp((props) => (props.paddingTop = value));
	const setPaddingRight = (_, value) => setProp((props) => (props.paddingRight = value));
	const setPaddingBottom = (_, value) => setProp((props) => (props.paddingBottom = value));
	const setPaddingLeft = (_, value) => setProp((props) => (props.paddingLeft = value));
	const setFontFamily = (e) => setProp((props) => (props.fontFamily = e.target.value));
	const setHref = (e) => setProp((props) => (props.href = e.target.value));
	const setTitle = (e) => setProp((props) => (props.title = e.target.value));
	const fontList = [
		{ value: 'Montserrat', label: 'Montserrat' },
		{ value: 'Poppins', label: 'Poppins' },
		{ value: 'Newsreader', label: 'Newsreader' },
		{ value: 'Lato', label: 'Lato' },
		{ value: 'Rubik', label: 'Rubik' },
		{ value: 'Dancing Script', label: 'Dancing Script' },
		{ value: 'Comfortaa', label: 'Comfortaa' },
		{ value: 'Merriweather', label: 'Merriweather' },
		{ value: 'Lora', label: 'Lora' },
	];
	return (
		<Box>
			<OptionTitle>URL</OptionTitle>
			<TextField onChange={setHref} value={href} placeholder="https://google.com" />
			<OptionTitle>Testo</OptionTitle>
			<TextField onChange={setTitle} value={title} />
			<TypographySettings
				setAlignment={setAlignment}
				textAlign={textAlign}
				setFontFamily={setFontFamily}
				fontFamily={fontFamily}
				fontList={fontList}
				setFontSize={setFontSize}
				fontSize={fontSize}
				setFontWeight={setFontWeight}
				fontWeight={fontWeight}
			/>
			<Margins
				top={paddingTop}
				bottom={paddingBottom}
				left={paddingLeft}
				right={paddingRight}
				setTop={setPaddingTop}
				setBottom={setPaddingBottom}
				setLeft={setPaddingLeft}
				setRight={setPaddingRight}
			/>
			<ControlWrapper title="Colore">
				<Colors onChange={setColor} value={color} label="Colore testo" />
			</ControlWrapper>
		</Box>
	);
};

export default LinkSettings;
