import { useNode } from '@craftjs/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ControlWrapper from '../../controls/_wrapper';
import Colors from '../../controls/colors';
import Selector from '../../controls/selector';
import { OptionTitle } from '../../controls/styled';
import TextAlignment from '../../controls/textAlign';

const HeadingSettings = () => {
	const {
		actions: { setProp },
		as,
		textAlign,
		color,
	} = useNode((node) => ({
		as: node.data.props.as,
		textAlign: node.data.props.textAlign,
		color: node.data.props.color,
	}));

	const setAlignment = (_, alignment) => {
		setProp((props) => (props.textAlign = alignment));
	};
	const setColor = (color) => {
		const {
			rgb: { r, g, b, a },
		} = color;
		setProp((props) => (props.color = `rgba(${r}, ${g}, ${b}, ${a})`));
	};
	const setHeading = (e) => {
		setProp((props) => (props.as = e.target.value));
	};
	const headingValues = [
		{ value: 'h1', label: 'H1' },
		{ value: 'h2', label: 'H2' },
		{ value: 'h3', label: 'H3' },
		{ value: 'h4', label: 'H4' },
		{ value: 'h5', label: 'H5' },
		{ value: 'h6', label: 'H6' },
	];
	return (
		<Box>
			<ControlWrapper title="Tipografia">
				<Grid container spacing={2}>
					<Grid item lg={12} xl={6}>
						<OptionTitle>Grandezza</OptionTitle>
						<Selector items={headingValues} value={as} onChange={setHeading} />
					</Grid>
					<Grid item lg={12} xl={6}>
						<OptionTitle>Allineamento</OptionTitle>
						<TextAlignment onChange={setAlignment} value={textAlign} />
					</Grid>
				</Grid>
			</ControlWrapper>
			<ControlWrapper title="Colore">
				<Colors onChange={setColor} value={color} label="Colore testo" />
			</ControlWrapper>
		</Box>
	);
};

export default HeadingSettings;
